import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import { clamp } from "services/util";
import {
  MAX_GRID_SIZE,
  MIN_GRID_SIZE,
  DEFAULT_GRID_SIZE
} from "services/constants";

import "./index.scss";

const NewPuzzleDialog = ({ open, onClose, onSubmit }) => {
  const [size, setSize] = useState(DEFAULT_GRID_SIZE);

  const incrementSize = () =>
    setSize(clamp(size + 1, MIN_GRID_SIZE, MAX_GRID_SIZE));

  const decrementSize = () =>
    setSize(clamp(size - 1, MIN_GRID_SIZE, MAX_GRID_SIZE));

  const handleSubmit = () => onSubmit(size);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          <div className="new-puzzle-dialog__control">
            <IconButton onClick={decrementSize} color="secondary">
              <RemoveCircleOutlineIcon />
            </IconButton>
            <span className="new-puzzle-dialog__indicator">
              <span className="new-puzzle-dialog__size">{size}</span>
              <span className="new-puzzle-dialog__times">×</span>
              <span className="new-puzzle-dialog__size">{size}</span>
            </span>
            <IconButton onClick={incrementSize} color="secondary">
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
          <div className="new-puzzle-dialog__label">Grid Size</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disableElevation
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewPuzzleDialog;
