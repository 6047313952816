import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none"
    }
  },
  palette: {
    primary: {
      main: "#25ccb8",
      contrastText: "#fff"
    },
    secondary: {
      main: "#fd9506",
      contrastText: "#fff"
    }
  },
  props: {
    MuiTooltip: {
      placement: "top"
    },
    MuiIconButton: {
      disableFocusRipple: true,
      disableRipple: true
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.9)"
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        paddingTop: 4,
        paddingBottom: 4,
        "&:hover": {
          color: "#25ccb8",
          backgroundColor: "none"
        }
      }
    }
  }
});

export default theme;
