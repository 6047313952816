import { newPuzzle } from "services/puzzle";

export const MAX_GRID_SIZE = 15;
export const MIN_GRID_SIZE = 3;
export const DEFAULT_GRID_SIZE = 5;

export const CELL_SIZE = 48;

export const DEFAULT_TITLE = "New Crossword Puzzle";

export const DEFAULT_PUZZLE_STATE = newPuzzle(DEFAULT_GRID_SIZE, DEFAULT_TITLE);

export const ROOT_URL = "https://www.blankgrid.app";

export const CurrentTool = {
  TEXT: "text",
  FILL: "fill"
};
