import { saveAs } from "file-saver";
import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

import { dirtyState } from "state";

import useCurrentPuzzle from "hooks/puzzle/useCurrentPuzzle";
import useCreatePuzzle from "hooks/puzzle/useCreatePuzzle";

const useImportExport = () => {
  const { id, puzzle } = useCurrentPuzzle();
  const createPuzzle = useCreatePuzzle();
  const history = useHistory();
  const setDirty = useSetRecoilState(dirtyState(id));

  const exportGrid = () => {
    const blob = new Blob([JSON.stringify(puzzle)], {
      type: "application/json"
    });

    saveAs(blob, `${puzzle.title}.json`);

    setDirty(false);
  };

  const importGrid = async file => {
    const json = await file.text();
    const data = JSON.parse(json);

    const id = createPuzzle(data);
    history.push(`/new/${id}`);
  };

  return { exportGrid, importGrid };
};

export default useImportExport;
