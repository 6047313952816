import useCurrentPuzzle from "hooks/puzzle/useCurrentPuzzle";

import "./index.scss";

const Title = ({ editable = false }) => {
  const {
    puzzle: { title },
    setTitle
  } = useCurrentPuzzle();

  const handleChange = e => {
    if (!editable) return;
    setTitle(e.target.innerText);
  };

  return (
    <h1
      className="title"
      contentEditable={editable}
      suppressContentEditableWarning={true}
      onBlur={handleChange}
    >
      {title}
    </h1>
  );
};

export default Title;
