import { atom, atomFamily, selectorFamily } from "recoil";
import isEqual from "lodash.isequal";

import { buildHighlights } from "services/grid";
import { DEFAULT_PUZZLE_STATE, CurrentTool } from "services/constants";

import { localStorageEffect } from "./effects";

export const puzzleState = atomFamily({
  key: "puzzleState",
  default: DEFAULT_PUZZLE_STATE,
  effects_UNSTABLE: id => [localStorageEffect(`blank-grid/puzzle/${id}`)]
});

export const cursorState = atomFamily({
  key: "cursorState",
  default: {
    selection: null,
    direction: "across"
  }
});

export const dirtyState = atomFamily({
  key: "dirtyState",
  default: false
});

export const currentToolState = atom({
  key: "currentToolState",
  default: CurrentTool.TEXT
});

export const gridHightlightState = selectorFamily({
  key: "gridHightlightState",
  get: id => ({ get }) => {
    const { grid } = get(puzzleState(id));
    const cursor = get(cursorState(id));

    return buildHighlights(grid, cursor);
  }
});

export const warningState = selectorFamily({
  key: "warningState",
  get: id => ({ get }) => {
    const message = "Adjusting the grid will clear all clues.";

    const currentTool = get(currentToolState);

    if (currentTool !== CurrentTool.FILL) return false;

    const { grid } = get(puzzleState(id));

    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c < grid.length; c++) {
        const clue = grid[r][c].clue;
        if (clue.across && clue.across.length > 0) return message;
        if (clue.down && clue.down.length > 0) return message;
      }
    }

    return null;
  }
});

export const clueState = selectorFamily({
  key: "clueState",
  get: id => ({ get }) => {
    const { grid } = get(puzzleState(id));
    const clues = { across: [], down: [] };

    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c < grid.length; c++) {
        const cell = grid[r][c];

        if (!cell.clue) continue;

        if (cell.clue.across != null) {
          clues.across.push({
            number: cell.clue.number,
            content: cell.clue.across,
            row: r,
            column: c
          });
        }
        if (cell.clue.down != null) {
          clues.down.push({
            number: cell.clue.number,
            content: cell.clue.down,
            row: r,
            column: c
          });
        }
      }
    }

    return clues;
  }
});
