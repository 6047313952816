import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import StarsIcon from "@material-ui/icons/Stars";

import "./index.scss";

const CompleteDialog = ({ open, onClose }) => (
  <Dialog
    className="viewer-complete-dialog"
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>
      <div className="viewer-complete-dialog__title">
        <StarsIcon />
        Congratulations!!!
      </div>
    </DialogTitle>
    <DialogContent>
      <DialogContentText className="viewer-complete-dialog__text">
        You solved the puzzle! Great Job!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default CompleteDialog;
