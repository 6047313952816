import { useRecoilValue } from "recoil";

import classNames from "services/classNames";
import { dirtyState, warningState } from "state";
import useCurrentPuzzleId from "hooks/puzzle/useCurrentPuzzleId";

import "./index.scss";

const Status = () => {
  const id = useCurrentPuzzleId();
  const isDirty = useRecoilValue(dirtyState(id));
  const warning = useRecoilValue(warningState(id));

  const dirtyMessage = isDirty ? "Unsaved Changes" : null;

  const className = classNames("status", { "status--warning": warning });

  return <div className={className}>{warning ? warning : dirtyMessage}</div>;
};

export default Status;
