import { updateClues, buildGrid } from "services/grid";
import { DEFAULT_TITLE } from "services/constants";

export const newPuzzle = (size, title = DEFAULT_TITLE) => ({
  title,
  grid: updateClues(buildGrid(size, { value: "", block: false }))
});

export const mergePuzzle = (pz1, pz2) => {
  console.log(pz2);
  const puzzle = JSON.parse(JSON.stringify(pz1));
  const grid = puzzle.grid;
  const gridSize = grid.length;

  for (let r = 0; r < gridSize; r++) {
    for (let c = 0; c < gridSize; c++) {
      grid[r][c].clue = grid[r][c].clue || {};
      grid[r][c].value = grid[r][c].value || pz2.grid[r][c].value;
    }
  }

  return { ...puzzle, grid };
};
