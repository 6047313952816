import { useRef } from "react";
import { useRecoilValue } from "recoil";

import useCurrentPuzzle from "hooks/puzzle/useCurrentPuzzle";
import useCursor from "hooks/useCursor";
import useResizeListener from "hooks/useResizeListener";
import { CELL_SIZE, CurrentTool } from "services/constants";
import { gridHightlightState, currentToolState } from "state";

import Cell from "./components/Cell";

import "./index.scss";

const Grid = () => {
  const ref = useRef();
  const inputRef = useRef();
  const rect = useResizeListener(ref);
  const { grid, updateCell, toggleCellBlock, id } = useCurrentPuzzle();
  const currentTool = useRecoilValue(currentToolState);

  const gridSize = (grid && grid.length) || 1;

  const {
    selectCell,
    selectedCell,
    advanceCursor,
    retractCursor,
    clearSelection,
    toggleCursorDirection
  } = useCursor(grid);

  const gridHighlight = useRecoilValue(gridHightlightState(id));

  const width = rect ? rect.width : 10;
  const cellSize = Math.min(
    CELL_SIZE,
    Math.floor((width - gridSize) / gridSize)
  );

  const isSelected = (r, c) => {
    if (selectedCell == null) return false;
    return selectedCell[0] === r && selectedCell[1] === c;
  };

  const handleCellClick = (r, c) => e => {
    if (currentTool === CurrentTool.FILL) {
      toggleCellBlock(r, c);
    } else {
      e.stopPropagation();

      /* Hidden input is need to trigger iOS keyboard */
      inputRef.current.focus();

      if (!grid[r][c].block) {
        if (isSelected(r, c)) {
          toggleCursorDirection();
        } else {
          selectCell(r, c);
        }
      }
    }
  };

  const handleKeyDown = e => {
    if (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey) return;
    if (!/^[a-z]$|^Backspace$/.test(e.key)) return;
    if (selectedCell == null) return;

    e.preventDefault();
    e.stopPropagation();

    const [r, c] = selectedCell;

    const val = e.key === "Backspace" ? "" : e.key;

    updateCell(r, c, val);

    if (e.key !== "Backspace") {
      advanceCursor();
    } else if (e.key === "Backspace") {
      retractCursor();
    }
  };

  return (
    <div className="grid-container" ref={ref}>
      <input
        className="grid__input"
        ref={inputRef}
        onKeyDown={handleKeyDown}
        autoCapitalize="off"
        autoComplete="off"
      />
      <div
        className="grid"
        style={{
          gridTemplate: `repeat(${gridSize}, auto) / repeat(${gridSize}, auto)`
        }}
        tabIndex="0"
        onBlur={clearSelection}
      >
        {grid.map((row, i) =>
          row.map((cell, j) => (
            <Cell
              key={`${i}-${j}`}
              size={cellSize}
              selected={isSelected(i, j)}
              onClick={handleCellClick(i, j)}
              isBlock={cell.block}
              value={cell.value}
              clue={cell.clue.number}
              highlighted={gridHighlight[i][j]}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Grid;
