import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import Main from "components/Main";
import Header from "components/Header";
import Title from "components/Title";
import Grid from "components/Grid";
import Clues from "components/Clues";
import Logo from "components/Logo";
import useData from "hooks/useData";
import { puzzleState } from "state";
import { fetchPuzzle } from "services/firebase";
import { mergePuzzle } from "services/puzzle";

import ViewerMessages from "./components/ViewerMessages";
import CompleteDialog from "./components/CompleteDialog";

import "./index.scss";

const Viewer = () => {
  const { id } = useParams();
  const [puzzle, setPuzzle] = useRecoilState(puzzleState(id));
  const [completeOpen, setCompleteOpen] = useState(false);

  const getPuzzle = useCallback(() => {
    return fetchPuzzle(id);
  }, [id]);

  const { data, loading, error } = useData(getPuzzle);

  useEffect(() => {
    if (data != null) {
      setPuzzle(puzzle =>
        puzzle == null || puzzle.grid.length !== data.grid.length
          ? data
          : mergePuzzle(data, puzzle)
      );
    }
  }, [data, setPuzzle]);

  const isComplete =
    puzzle &&
    puzzle.grid.every(row =>
      row.every(cell => cell.block || cell.value === cell.answer)
    );

  useEffect(() => {
    if (isComplete) {
      setCompleteOpen(true);
    }
  }, [isComplete]);

  return (
    <>
      <Header className="viewer__header">
        <Logo />
      </Header>
      <Main>
        <ViewerMessages loading={loading} error={error} />
        {!loading && !error && data != null && puzzle != null ? (
          <>
            <CompleteDialog
              open={completeOpen}
              onClose={() => setCompleteOpen(false)}
            />
            <Title />
            <Grid />
            <Clues />
          </>
        ) : null}
      </Main>
    </>
  );
};

export default Viewer;
