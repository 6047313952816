import IconButton from "@material-ui/core/IconButton";
import ArchiveIcon from "@material-ui/icons/Archive";
import Tooltip from "@material-ui/core/Tooltip";

import useImportExport from "hooks/puzzle/useImportExport";
import useShortcuts from "hooks/useShortcuts";

const ExportButton = buttonProps => {
  const { exportGrid } = useImportExport();

  useShortcuts("s", exportGrid);

  return (
    <Tooltip title="Download">
      <IconButton onClick={exportGrid} {...buttonProps}>
        <ArchiveIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ExportButton;
