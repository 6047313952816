import React from "react";

const Clue = ({ onChange, number, children, editable }) => {
  const handleBlur = e => {
    if (editable) {
      onChange(e.target.innerText);
    }
  };

  return (
    <li
      value={number}
      contentEditable={editable}
      suppressContentEditableWarning={true}
      onBlur={handleBlur}
    >
      {children}
    </li>
  );
};

export default Clue;
