const getStringsFromObj = obj =>
  Object.keys(obj).reduce(
    (strings, key) => (obj[key] ? [...strings, key] : strings),
    []
  );

const reducer = (strings, arg) => {
  if (arg == null) {
    return strings;
  }

  switch (typeof arg) {
    case "object":
      return [...strings, ...getStringsFromObj(arg)];
    case "string":
      return [...strings, arg];
    default:
      return strings;
  }
};

/**
 * Function that takes in arguments of either strings or objects and assembles
 * them into a string to be used as a classname.
 *
 * Object keys will be used as a classname if the key value is truthy.
 *
 * Any params that are not strings or objects are ignored
 *
 * For example:
 *
 * classNames(
 *  'className',
 *  null,
 *  false,
 *  {
 *    className--active: false,
 *    className--disabled: true
 *  }
 * ) === "className className--disabled"
 *
 * @param  {...(Object | String)} args
 */
const classNames = (...args) => {
  const strings = args.reduce(reducer, []);

  return strings.length > 0 ? strings.join(" ") : null;
};

export default classNames;
