import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ReplyIcon from "@material-ui/icons/Reply";
import Tooltip from "@material-ui/core/Tooltip";

import { persistPuzzle } from "services/firebase";
import { ROOT_URL } from "services/constants";
import useCurrentPuzzle from "hooks/puzzle/useCurrentPuzzle";

import ShareDialog from "./components/ShareDialog";

import "./index.scss";

const ShareButton = buttonProps => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const { puzzle } = useCurrentPuzzle();

  const handleClick = async () => {
    const id = await persistPuzzle(puzzle);
    setUrl(`${ROOT_URL}/play/${id}`);
    setOpen(true);
  };

  return (
    <>
      <Tooltip title="Share Puzzle">
        <IconButton {...buttonProps} onClick={handleClick}>
          <ReplyIcon className="share-button__icon" />
        </IconButton>
      </Tooltip>
      <ShareDialog open={open} url={url} onClose={() => setOpen(false)} />
    </>
  );
};

export default ShareButton;
