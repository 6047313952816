import { ROOT_URL } from "services/constants";

import "./index.scss";

const Logo = () => (
  <a className="logo" href={ROOT_URL}>
    <span>Blank</span>
    <span>Grid</span>
  </a>
);

export default Logo;
