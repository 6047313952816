import { useRecoilState } from "recoil";
import IconButton from "@material-ui/core/IconButton";
import BrushIcon from "@material-ui/icons/Brush";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import Tooltip from "@material-ui/core/Tooltip";

import { CurrentTool } from "services/constants";
import { currentToolState } from "state";

const ToolButtons = buttonProps => {
  const [currentTool, setCurrentTool] = useRecoilState(currentToolState);

  return (
    <>
      <Tooltip title="Text Tool">
        <IconButton
          {...buttonProps}
          onClick={() => setCurrentTool(CurrentTool.TEXT)}
          color={currentTool === CurrentTool.TEXT ? "primary" : "default"}
        >
          <FontDownloadIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Block Tool">
        <IconButton
          {...buttonProps}
          onClick={() => setCurrentTool(CurrentTool.FILL)}
          color={currentTool === CurrentTool.FILL ? "primary" : "default"}
        >
          <BrushIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ToolButtons;
