import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import { dirtyState } from "state";

import NewPuzzleDialog from "./components/NewPuzzleDialog";

import useCurrentPuzzleId from "hooks/puzzle/useCurrentPuzzleId";
import { newPuzzle } from "services/puzzle";
import useCreatePuzzle from "hooks/puzzle/useCreatePuzzle";

const NewButton = buttonProps => {
  const id = useCurrentPuzzleId();
  const isDirty = useRecoilValue(dirtyState(id));
  const history = useHistory();
  const createPuzzle = useCreatePuzzle();

  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleClick = () => {
    if (isDirty) {
      if (
        !window.confirm(
          "The current puzzle has unsaved changes. Are you sure you want to create a new puzzle?"
        )
      ) {
        return;
      }
    }

    openDialog();
  };

  const handleSubmit = size => {
    const id = createPuzzle(newPuzzle(size));
    closeDialog();
    history.push(`/new/${id}`);
  };

  return (
    <>
      <Tooltip title="New">
        <IconButton onClick={handleClick} {...buttonProps}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <NewPuzzleDialog
        open={dialogOpen}
        onClose={closeDialog}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default NewButton;
