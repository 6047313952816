const isBlock = (grid, r, c) => {
  const gridSize = grid.length;

  if (r < 0 || c < 0) return true;
  if (r >= gridSize || c >= gridSize) return true;

  return grid[r][c].block;
};

export const updateClues = originalGrid => {
  const grid = JSON.parse(JSON.stringify(originalGrid));

  const gridSize = grid.length;

  let i = 1;

  for (let r = 0; r < gridSize; r++) {
    for (let c = 0; c < gridSize; c++) {
      grid[r][c].clue = {};

      if (grid[r][c].block) continue;

      /* Across clues */
      if (isBlock(grid, r, c - 1) && !isBlock(grid, r, c + 1)) {
        grid[r][c].clue.number = grid[r][c].clue.number || i++;
        grid[r][c].clue.across = "";
      }

      /* Down clues */
      if (isBlock(grid, r - 1, c) && !isBlock(grid, r + 1, c)) {
        grid[r][c].clue.number = grid[r][c].clue.number || i++;
        grid[r][c].clue.down = "";
      }
    }
  }

  return grid;
};

export const buildGrid = (size, fill) => {
  const grid = [];

  for (let i = 0; i < size; i++) {
    grid[i] = [];
    for (let j = 0; j < size; j++) {
      grid[i][j] = fill;
    }
  }

  return grid;
};

export const buildHighlights = (grid, cursor) => {
  const highlights = buildGrid(grid.length, false);

  if (!cursor.selection) return highlights;

  if (cursor.direction === "across") {
    const r = cursor.selection[0];
    let c = cursor.selection[1] + 1;

    while (!isBlock(grid, r, c)) {
      highlights[r][c] = true;
      c++;
    }

    c = cursor.selection[1] - 1;

    while (!isBlock(grid, r, c)) {
      highlights[r][c] = true;
      c--;
    }
  } else {
    let r = cursor.selection[0] + 1;
    const c = cursor.selection[1];

    while (!isBlock(grid, r, c)) {
      highlights[r][c] = true;
      r++;
    }

    r = cursor.selection[0] - 1;

    while (!isBlock(grid, r, c)) {
      highlights[r][c] = true;
      r--;
    }
  }

  return highlights;
};
