import { useEffect, useState } from "react";

const useResizeListener = ref => {
  const [rect, setRect] = useState(null);

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }

    const handler = e => {
      setRect(ref.current.getBoundingClientRect());
    };

    window.addEventListener("resize", handler);

    const cleanup = () => window.removeEventListener("resize", handler);

    return cleanup;
  }, [ref]);

  return rect;
};

export default useResizeListener;
