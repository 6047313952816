import { useRef, useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import Tooltip from "@material-ui/core/Tooltip";

import useImportExport from "hooks/puzzle/useImportExport";
import useShortcuts from "hooks/useShortcuts";

const ExportButton = buttonProps => {
  const fileInputRef = useRef();
  const { importGrid } = useImportExport();

  const openFileDialog = useCallback(() => fileInputRef.current.click(), []);

  useShortcuts("o", openFileDialog);

  const handleFileChange = async e => {
    if (e.target.files.length !== 1) return;

    importGrid(e.target.files[0]);

    e.target.value = null;
  };

  return (
    <>
      <Tooltip title="Open">
        <IconButton onClick={openFileDialog} {...buttonProps}>
          <FolderOpenIcon />
        </IconButton>
      </Tooltip>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={handleFileChange}
      />
    </>
  );
};

export default ExportButton;
