import axios from "axios";
import { generate as shortid } from "shortid";

const fb = axios.create({
  baseURL: "https://blank-grid-default-rtdb.firebaseio.com/"
});

export const persistPuzzle = async puzzle => {
  const id = shortid();

  const payload = {
    createdAt: Date.now(),
    puzzle
  };

  await fb.put(`puzzles/${id}.json`, payload);

  return id;
};

export const fetchPuzzle = async id => {
  const resp = await fb.get(`puzzles/${id}.json`);
  const puzzle = resp.data.puzzle;

  const grid = puzzle.grid;
  const gridSize = grid.length;

  for (let r = 0; r < gridSize; r++) {
    for (let c = 0; c < gridSize; c++) {
      grid[r][c].clue = grid[r][c].clue || {};
      grid[r][c].answer = grid[r][c].value;
      grid[r][c].value = null;
    }
  }

  return { ...puzzle, grid };
};
