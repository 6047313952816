import CircularProgress from "@material-ui/core/CircularProgress";

import "./index.scss";

const ViewerMessages = ({ loading, error }) =>
  loading || error ? (
    <div className="viewer-messages">
      {loading ? <CircularProgress /> : null}{" "}
      {error ? (
        <div className="viewer-messages__error">
          There was an error loading the puzzle.
        </div>
      ) : null}
    </div>
  ) : null;

export default ViewerMessages;
