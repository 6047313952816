import { useEffect } from "react";

const useShortcuts = (key, handleKey) => {
  useEffect(() => {
    const handler = e => {
      if (e.metaKey || e.ctrlKey) {
        if (e.key === key) {
          e.preventDefault();
          e.stopPropagation();
          handleKey();
        }
      }
    };

    window.addEventListener("keydown", handler);

    const cleanup = () => window.removeEventListener("keydown", handler);

    return cleanup;
  }, [key, handleKey]);
};

export default useShortcuts;
