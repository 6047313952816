import { useRecoilValue } from "recoil";

import useCurrentPuzzle from "hooks/puzzle/useCurrentPuzzle";
import { clueState } from "state";

import Clue from "./components/Clue";

import "./index.scss";

const Clues = ({ editable = false }) => {
  const { id, updateClue } = useCurrentPuzzle();
  const clues = useRecoilValue(clueState(id));

  const handleChange = (r, c, direction) => content =>
    updateClue(r, c, direction, content);

  return (
    <div className="clues">
      <div className="clues__across">
        <h3>Across</h3>
        <ol className="clues__list">
          {clues.across.map(({ content, number, row, column }) => (
            <Clue
              key={`${number}-across`}
              number={number}
              editable={editable}
              onChange={handleChange(row, column, "across")}
            >
              {content}
            </Clue>
          ))}
        </ol>
      </div>
      <div className="clues__divider" />
      <div className="clues__down">
        <h3>Down</h3>
        <ol className="clues__list">
          {clues.down.map(({ content, number, row, column }) => (
            <Clue
              key={`${number}-across`}
              number={number}
              editable={editable}
              onChange={handleChange(row, column, "down")}
            >
              {content}
            </Clue>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Clues;
