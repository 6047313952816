import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import { generate as shortid } from "shortid";

import Editor from "scenes/Editor";
import Viewer from "scenes/Viewer";

import "./index.scss";

const App = () => {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/play/:id">
            <Viewer />
          </Route>
          <Route path="/new/:id">
            <Editor />
          </Route>
          <Route path="*">
            <Redirect to={`/new/${shortid()}`} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
