import { useRecoilCallback } from "recoil";
import { generate as shortid } from "shortid";

import { puzzleState } from "state";

const useCreatePuzzle = () => {
  const createNewPuzzle = useRecoilCallback(
    ({ set }) => puzzle => {
      const id = shortid();
      set(puzzleState(id), puzzle);
      return id;
    },
    []
  );

  return createNewPuzzle;
};

export default useCreatePuzzle;
