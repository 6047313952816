import { useEffect, useReducer } from "react";

const DEFAULT_STATE = {
  error: false,
  loading: false,
  data: null
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "error":
      return { ...DEFAULT_STATE, error: payload };
    case "loading":
      return { ...state, loading: true };
    case "data":
      return { ...DEFAULT_STATE, data: payload };
    default:
      throw new Error("Unknown action recieved");
  }
};

const useData = (apiMethod, { defaultError = "Error loading data" } = {}) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: "loading" });

        const data = await apiMethod();

        dispatch({ type: "data", payload: data });
      } catch (err) {
        console.error(err);

        dispatch({
          type: "error",
          payload: err.message || defaultError
        });
      }
    })();
  }, [apiMethod, defaultError]);

  return state;
};

export default useData;
