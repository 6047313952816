import React from "react";

import Header from "components/Header";
import NewButton from "components/NewButton";
import ExportButton from "components/ExportButton";
import ImportButton from "components/ImportButton";
import ShareButton from "components/ShareButton";
import ToolButtons from "components/ToolButtons";

import "./index.scss";

const Toolbar = () => (
  <Header>
    <div className="toolbar">
      <div className="toolbar__button-group--left">
        <NewButton className="toolbar__button" />
        <ImportButton className="toolbar__button" />
        <ExportButton className="toolbar__button" />
      </div>
      <div className="toolbar__button-group--center">
        <ToolButtons className="toolbar__button" />
      </div>
      <div className="toolbar__button-group--right">
        <ShareButton className="toolbar__button" />
      </div>
    </div>
  </Header>
);

export default Toolbar;
