import Main from "components/Main";
import Status from "components/Status";
import Title from "components/Title";
import Grid from "components/Grid";
import Toolbar from "components/Toolbar";
import Clues from "components/Clues";

const Editor = () => (
  <>
    <Toolbar />
    <Main>
      <Status />
      <Title editable={true} />
      <Grid />
      <Clues editable={true} />
    </Main>
  </>
);

export default Editor;
