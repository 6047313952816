import { useRecoilValue } from "recoil";

import { CELL_SIZE } from "services/constants";

import classNames from "services/classNames";

import "./index.scss";

const Cell = ({
  selected,
  highlighted,
  onClick,
  value,
  isBlock,
  clue,
  size
}) => {
  const scale = size / CELL_SIZE;

  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      height={size}
      onClick={onClick}
      className={classNames("grid-cell", {
        "grid-cell--block": isBlock,
        "grid-cell--selected": selected,
        "grid-cell--highlighted": highlighted
      })}
    >
      <g transform={`scale(${scale})`}>
        {clue != null ? (
          <text
            x={4}
            y={8}
            alignmentBaseline="middle"
            className="grid-cell__clue"
          >
            {clue}
          </text>
        ) : null}
        <text
          x={CELL_SIZE / 2}
          y={CELL_SIZE / 2}
          textAnchor="middle"
          alignmentBaseline="central"
          className="grid-cell__content no-print"
        >
          {value ? value.toUpperCase() : null}
        </text>
      </g>
    </svg>
  );
};

export default Cell;
