import { useRecoilState } from "recoil";

import { cursorState } from "state";
import { transpose } from "services/util";

import useCurrentPuzzleId from "hooks/puzzle/useCurrentPuzzleId";

const DEFAULT_STATE = {
  selection: null,
  direction: "across"
};

const useCursor = grid => {
  const id = useCurrentPuzzleId();

  const [state, setState] = useRecoilState(cursorState(id));

  const selectCell = (r, c) => setState({ ...state, selection: [r, c] });

  const clearSelection = () => setState(DEFAULT_STATE);

  const advanceCursor = () => {
    if (!state.selection) return;

    const gridSize = grid.length;

    if (state.direction === "across") {
      for (
        let i = state.selection[0] * gridSize + state.selection[1] + 1;
        i < gridSize * gridSize;
        i++
      ) {
        const r = Math.floor(i / gridSize);
        const c = i % gridSize;

        if (!grid[r][c].block) {
          return selectCell(r, c);
        }
      }
    } else {
      const lookup = transpose(grid);

      for (
        let i = state.selection[1] * gridSize + state.selection[0] + 1;
        i < gridSize * gridSize;
        i++
      ) {
        const r = Math.floor(i / gridSize);
        const c = i % gridSize;

        if (!lookup[r][c].block) {
          return selectCell(c, r);
        }
      }
    }
  };

  const retractCursor = () => {
    if (!state.selection) return;

    const gridSize = grid.length;

    if (state.direction === "across") {
      for (
        let i = state.selection[0] * gridSize + state.selection[1] - 1;
        i >= 0;
        i--
      ) {
        const r = Math.floor(i / gridSize);
        const c = i % gridSize;

        if (!grid[r][c].block) {
          return selectCell(r, c);
        }
      }
    } else {
      const lookup = transpose(grid);

      for (
        let i = state.selection[1] * gridSize + state.selection[0] - 1;
        i >= 0;
        i--
      ) {
        const r = Math.floor(i / gridSize);
        const c = i % gridSize;

        if (!lookup[r][c].block) {
          return selectCell(c, r);
        }
      }
    }
  };

  const toggleCursorDirection = () => {
    setState({
      ...state,
      direction: state.direction === "across" ? "down" : "across"
    });
  };

  return {
    selectCell,
    clearSelection,
    selectedCell: state.selection,
    advanceCursor,
    retractCursor,
    toggleCursorDirection
  };
};

export default useCursor;
