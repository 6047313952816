import { useRecoilState, useSetRecoilState } from "recoil";

import { puzzleState, dirtyState } from "state";
import { updateClues } from "services/grid";
import useCurrentPuzzleId from "hooks/puzzle/useCurrentPuzzleId";
import useCursor from "hooks/useCursor";

const useCurrentPuzzle = () => {
  const id = useCurrentPuzzleId();

  const [puzzle, setPuzzle] = useRecoilState(puzzleState(id));
  const setDirty = useSetRecoilState(dirtyState(id));
  const { clearSelection } = useCursor(id);

  const grid = puzzle.grid;

  const setCell = (r, c, newCell, recreate = false) => {
    const newGrid = grid.map((row, i) =>
      row.map((cell, j) => (i === r && j === c ? newCell : cell))
    );

    setPuzzle({ ...puzzle, grid: recreate ? updateClues(newGrid) : newGrid });
    setDirty(true);
  };

  const updateCell = (r, c, value) => {
    const cell = grid[r][c];

    if (cell.block) return;

    setCell(r, c, { ...cell, value });
  };

  const toggleCellBlock = (r, c) => {
    const cell = grid[r][c];

    const newValue = cell.block
      ? { value: "", block: false }
      : { value: null, block: true };

    setCell(r, c, newValue, true);

    clearSelection();
  };

  const updateClue = (r, c, direction, content) => {
    const cell = grid[r][c];
    const clue = cell.clue;

    setCell(r, c, { ...cell, clue: { ...clue, [direction]: content } });
  };

  const setTitle = title => setPuzzle({ ...puzzle, title });

  return {
    id,
    puzzle,
    grid,
    updateCell,
    updateClue,
    toggleCellBlock,
    setTitle
  };
};

export default useCurrentPuzzle;
